













































import { Vue, Component } from 'vue-property-decorator';
import { convertDateToYear } from '@/utils/moment';
@Component({})
export default class ForgetPasswordLayout extends Vue {
  get currentYear() {
    return convertDateToYear(new Date());
  }
}
