
























import { Vue, Component } from 'vue-property-decorator';
import ForgetPasswordLayout from '@/components/login/ForgetPasswordLayout.vue';
@Component({
    components: { ForgetPasswordLayout }
})
export default class ResetPasswordEndPage extends Vue {

}
